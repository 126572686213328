<template>
  <div>
    <a-card title="基本信息" :bordered="false">
      <div slot="extra">
        <div class="space-between">
          <div><span>业务编号: {{ form.serial_num }}</span></div>
          <div>
            <a-button>
              <router-link :to="{ name: 'ItmsOffice' }">取消</router-link>
            </a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="handleSubmit">保存</a-button>
          </div>
        </div>
      </div>

      <a-form-model
        ref="form"
        layout="vertical"
        :model="form"
        :rules="rules"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="付款对象" prop="customer_id">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                placeholder="选择付款对象"
                v-model="form.customer_id"
              >
                <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="业务费用" prop="business_expenses">
              <a-select
                allowClear
                placeholder="选择业务费用"
                v-model="form.business_expenses"
              >
                <a-select-option v-for="item in businessExpensesList" :key="item.key" :value="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="时间" v-if="form.business_expenses !== '仓储材料费' && form.business_expenses !== '柴油费'">
              <a-range-picker @change="onDateChange"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-card title="费用信息" :bordered="false">
      <edit-table
        :columns="feeColumns"
        :sourceData="feeData"
        @getTableDate="getFeeData"
      ></edit-table>
    </a-card>
  </div>
</template>

<script>
import { getFirstLetter } from '@/utils/util'
import { saveItmsOffice, getItmsOrderDetail } from '@/api/itms'
import { EditTable } from '@/components'
import { getCommonOptions, getSupplier, initOfficeBusinessExpenses } from '@/api/common'

export default {
  name: 'ItmsOfficeCUR',
  components: {
    EditTable
  },
  data() {
    return {
      form: {
        customer_id: null,
        business_expenses: null,
        order_start_date: null,
        order_end_date: null,
        remark: null
      },
      rules: {
        customer_id: [{ required: true, message: '请选择付款对象', trigger: 'blur' }],
        business_expenses: [{ required: true, message: '请选择业务费用', trigger: 'blur' }]
      },
      supplierOps: [],
      businessExpensesList: [],
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      feeData: [],
      isEdit: false
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'ItmsOfficeCUR') {
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          this.isEdit = false
          this.$route.meta.title = '新增国贸行政'
        } else {
          this.isEdit = true
          this.$route.meta.title = '编辑国贸行政'
          this.getOrderDetail(newRoute.params.order_id)
        }
      }
    }
  },
  mounted() {
    this.handleFormReset()
    initOfficeBusinessExpenses().then(res => {
      this.businessExpensesList = res.OFFICE_BUSINESS_EXPENSES_TYPE_LIST.filter(item => item.department === 'WMS')
    })
    getSupplier({ 'department': 2, 'excludeBlack': 'Y' }).then(res => {
      this.supplierOps = res
    })
    if (this.$route.params.id !== 'create') {
      this.isEdit = true
      this.$route.meta.title = '编辑国贸行政'
      this.getOrderDetail(this.$route.params.order_id)
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            ...this.form,
            'pay_fee_list': this.feeData
          }
          saveItmsOffice(data).then(res => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.$router.push({
              name: 'ItmsOffice',
              query: {
                isEdit: this.isEdit
              }
            })
          })
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].valueOf()
        const endDate = date[1].valueOf()
        this.form['order_start_date'] = startDate
        this.form['order_end_date'] = endDate
      } else {
        this.form['order_start_date'] = null
        this.form['order_end_date'] = null
      }
    },
    getFeeData(data) {
      this.feeData = data
    },
    handleFormReset() {
      this.form = {
        customer_id: null,
        business_expenses: null,
        order_start_date: null,
        order_end_date: null,
        remark: null
      }
      this.feeData = []
    },
    getOrderDetail(id) {
      getItmsOrderDetail({ 'id': id }).then(res => {
        this.form = {
          'id': res.content.id,
          'customer_id': res.content.customer_id,
          'business_expenses': res.content.business_expenses,
          'order_start_date': res.content.order_start_date,
          'order_end_date': res.content.order_end_date,
          'remark': res.content.remark,
          'serial_num': res.order.serial_num
        }
        this.feeData = res.pay_fees ? res.pay_fees.map(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) {
            fee.disabled = true
          } else {
            fee.disabled = false
          }
          return {
            'id': fee.id,
            'fee_name_type': fee.fee_name_type,
            'money': fee.money,
            'fee_status': fee.fee_status,
            'is_payment': fee.is_payment
          }
        }) : []
      })
    }
  }
}
</script>

<style scoped>
.space-between {
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
